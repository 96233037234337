import React from 'react';
import "./PropertyPermit.scss";

const PropertyPermit = ({ permitNumber, zoneName, qrCode }) => {
    if (!permitNumber) {
        return null;
    }
    return (
        <div className="property-permit-info-wrapper">
            <h6>Property Permit Information</h6>
            <div className="permit-info-wrap">
            {qrCode ?
                <div className="qr-code-img">
                   <img src={qrCode} alt="QR Code" />
                </div>
                        : ""}
                <div className="permit-data">
                    <div className="permit-number-info">
                        {permitNumber && (
                            <div className="permit-number permit-info text-sm-bold">
                                Trakheesi Permit Number:<span className="text-sm">{permitNumber}</span>
                            </div>
                        )}
                        {zoneName && (
                            <div className="zone-name text-sm-bold">
                                Zone Name:<span className="text-sm">{zoneName}</span>
                            </div>
                        )}
                    </div>
                    <div className="disclaimer text-xxs">This listing has been verified by the Dubai Land Department.</div>
                </div>
            </div>
        </div>
    );
};

export default PropertyPermit;