/**
 * Mortgage Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col, Button, Form, OverlayTrigger, Tooltip, Table } from 'react-bootstrap';
import { filterNumber, numberFormat, pmt, calculateMonthlyPayment } from "./util"
import { defaultValues } from "./config"
import Select from 'react-select';
// use project specific import here
import {HelperIcon} from "../../Icons/Icons";

// User project specific const
//[10, 15, 20, 30, 35]
const durationOptions = [
  { value: 10, label: "10 Years" },
  { value: 15, label: "15 Years" },
  { value: 20, label: "20 Years" },
  { value: 25, label: "25 Years" }
]

const styles = {
  control: styles => ({ ...styles, backgroundColor: '#ffffff', border: 0,  outline: 0, boxShadow: 'none'}),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#4C505B',
    }
  },
  menu: (provided, state) => ({
    ...provided,
    zIndex: 99
  }),
}

// DO NOT DO ANY CHNAGES - START
const MortgageCalc = props => {
  const {
    propertyPrice,
    depositAmount,
    loadDuration,
    interestRate,
    currency,
    title,
    defaultResult,
    pricePrefix,
    // instantResult,
    // durationSuffix,
    // yearSuffix,
    // yearSelect,
    current
  } = props
  const prpoprice = typeof window !== 'undefined' ? localStorage.getItem('propertyPrice_cal') : null
  const prpocurr = typeof window !== 'undefined' ? localStorage.getItem('currTo') : null

  let PropertyPrice = prpoprice.replace(/,/g, '');
  var downPayment = (PropertyPrice / 100) * 20; 
  const loanAmt = numberFormat(propertyPrice - downPayment)

  const prefix = prpocurr ? prpocurr : pricePrefix ? currency : ""
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [deposit, setDeposit] = useState(prpocurr + ' ' + numberFormat(downPayment))
  const [purchasePrice, setPurchasePrice] = useState(
    prpocurr + ' ' + numberFormat(PropertyPrice - downPayment)
  )

  const [duration, setDuration] = useState(25)
  const [interest, setInterest] = useState(interestRate)
  const [loan, setLoan] = useState("")
  const [result, setResult] = useState("")
  const [mortgageCosts, setMortgageCosts] = useState({
    landDeptFee: 0,
    registrationFee: 0,
    mortgageRegnFee: 0,
    brokerCommissionFee: 0,
    bankArrangementFee: 0,
    valuationFee: 0,
    conveyancingFee: 0,
    totalFee: 0,
    upFront: 0
  })

  const landDeptFee = 4;
  const landDeptFeePlus = 580;
  const registrationFee = 4000;
  const registrationFeeLimit = 0;
  const mortgageRegnFee = 0.25;
  const mortgageRegnFeePlus = 290;
  const brokerCommissionFee = 2;
  const bankArrangementFee = 1;
  const valuationFee = 2750;
  const conveyancingFee = 6600;
  const includeVATperc = 5;
  const price = prpoprice.replace(/,/g, '');


  const handlePrice = event => {
    let val = filterNumber(event.target.value ? event.target.value : PropertyPrice)
    setPurchasePrice(prpocurr + ' ' + numberFormat(val))

    // if (parseInt(filterNumber(deposit)) && parseInt(val)) {
    //   let loan = parseInt(val) - parseInt(filterNumber(deposit))
    //   setLoan(prefix + numberFormat(loan))
    // } else {
    //   setLoan(prefix + 0)
    // }
  }

  useEffect(() => {
    if (prpoprice) {
      setPurchasePrice(prefix + ' ' + numberFormat(Math.round(PropertyPrice - downPayment)))
      setDeposit(prefix + ' ' + numberFormat(Math.round(downPayment)))
    }
  }, [prpoprice,prefix])

  const handleDeposit = event => {
    let d = filterNumber(event.target.value.replace(/[^\d.]/g, ""))
    setDeposit(prpocurr + ' ' + numberFormat(d))

    // if (filterNumber(propertyPrice) < d) {
    //   setPurchasePrice(prefix + "0")
    // }
    // else {
    //   setPurchasePrice(prefix + (filterNumber(propertyPrice) - d))
    // }

    // if (parseInt(filterNumber(purchasePrice)) && parseInt(d)) {
    //   let loan2 = parseInt(filterNumber(purchasePrice)) - parseInt(d)
    //   setLoan(prefix + numberFormat(loan2))
    // } else {
    //   setLoan(prefix + 0)
    // }
  }

  const handleDuration = event => {

    setDuration(filterNumber(event.value))
  }

  const handleInterest = (event) => {
    setInterest(event.target.value.replace(/[^\d.]/g, ""));
  }

  const handleLoan = event => {
    setLoan(prefix + numberFormat(filterNumber(event.target.value)))
  }

  const getResult = (interest, duration, loan) => {
    // let result = -pmt(
    //   interest / 100 / 12,
    //   filterNumber(duration) * 12,
    //   filterNumber(propertyPrice),
    //   0,
    //   1
    // )  
    // console.log(interest, duration, deposit, propertyPrice, calculateMonthlyPayment(propertyPrice, interest, filterNumber(deposit), duration))
    // let result = calculateMonthlyPayment(propertyPrice, interest, filterNumber(deposit), duration);
    // setResult(numberFormat(Math.round(result)))
    // console.log("result", interest, duration, deposit, loan, calculateMonthlyPayment(loan, interest, filterNumber(deposit), duration))
    let result = calculateMonthlyPayment(price, interest, filterNumber(deposit), duration);
    //land-dept-fee 
    let land_dept_fee = ((landDeptFee / 100) * price) + landDeptFeePlus;
    //Registration fee 
    let registration_fee = 0;
    if (price > registrationFeeLimit) {
      registration_fee = registrationFee;
    }
    let includeVAT_reg_fee = registration_fee / 100 * includeVATperc;

    //MortgageRegnFeePlus 
    let mortgage_regn_fee = ((mortgageRegnFee / 100) * loan) + mortgageRegnFeePlus;

    //broker_commission_fee
    let broker_commission_fee = (brokerCommissionFee / 100) * price;
    let brokert_includeVAT_fee = broker_commission_fee / 100 * includeVATperc;
    broker_commission_fee = broker_commission_fee + brokert_includeVAT_fee;

    //mortgage_process_fee
    let mortgage_process_fee = (bankArrangementFee / 100) * loan;

    // conveyancingFee 
    let conveyancing_fee = conveyancingFee;
    let con_includeVAT_fee = conveyancing_fee / 100 * includeVATperc;
    conveyancing_fee = conveyancing_fee + con_includeVAT_fee;

    let total_fees = (land_dept_fee + registration_fee + includeVAT_reg_fee + valuationFee + mortgage_regn_fee + broker_commission_fee + mortgage_process_fee + conveyancing_fee);

    setMortgageCosts({
      ...mortgageCosts,
      landDeptFee: numberFormat(Math.round(land_dept_fee)),
      registrationFee: numberFormat(Math.round(registration_fee + includeVAT_reg_fee)),
      mortgageRegnFee: numberFormat(Math.round(mortgage_regn_fee)),
      brokerCommissionFee: numberFormat(Math.round(broker_commission_fee)),
      bankArrangementFee: numberFormat(Math.round(mortgage_process_fee)),
      conveyancingFee: numberFormat(Math.round(conveyancing_fee)),
      valuationFee: numberFormat(valuationFee),
      totalFee: numberFormat(Math.round(total_fees)),
      upFront: numberFormat(Math.round(filterNumber(deposit) + total_fees))
    });


    setResult(numberFormat(Math.round(result)))
  }

  const [depositError, setDepError] = useState(false)
  const [intError, setIntError] = useState(false)

  const handleSubmit = event => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setShowerror(true)
      setValidated(true)
    } else {

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Calculator',
        'formType': title,
        'formId': "Mortgage Calculator",
        'formName': "Mortgage Calculator",
        'formLabel': "Mortgage Calculator"
      });
      setShowerror(false)
      setValidated(false)

      var par = purchasePrice.replace('AED', '').replace(/,/g, '')
      var dep = deposit.replace('AED', '').replace(/,/g, '')
      var par1 = parseInt(par)
      var dep1 = parseInt(dep)

      if (
        parseInt(filterNumber(purchasePrice)) &&
        parseInt(filterNumber(deposit))
      ) {
        let loan =
          parseInt(filterNumber(purchasePrice)) -
          parseInt(filterNumber(deposit))
        setLoan(prefix + numberFormat(loan))
        if (interest === "0") {

        }
        else if (par1 < dep1) {
          setShowerror(true)
          setDepError(true)
          setIntError(true)
          setResult("")
        }
        else if (par1 == dep1) {
          setShowerror(true)
          setDepError(false)
          setIntError(true)
          setResult("")
        }
        else {
          setShowerror(false)
          setDepError(false)
          getResult(interest, duration, loan)


        }

      }
      else if (dep1 == 0) {
        setShowerror(true)
        setDepError(false)
        setIntError(false)
        setResult("")
      }
      else if (par1 == 0) {
        setShowerror(true)
        setDepError(false)
        setIntError(false)
        setResult("")
      }

    }
  }
  useEffect(() => {
    // if (defaultResult) {
    if (
      parseInt(filterNumber(purchasePrice)) &&
      parseInt(filterNumber(deposit))
    ) {
      let loan = parseInt(filterNumber(purchasePrice)) - parseInt(filterNumber(deposit))
      setLoan(prefix + numberFormat(loan))
    }

    // getResult(interest, duration, loan)
    let depositeAmt = parseInt(filterNumber(deposit));
    let loanAmt = parseInt(filterNumber(purchasePrice));

    if (depositeAmt > 0 && filterNumber(interest) > 0) {
      // if (propertyPrice < depositeAmt) {
      //   setShowerror(true)
      //   setDepError(true)
      //   setIntError(true)
      //   setResult("")
      // } else {
      //   setShowerror(false)
      //   setDepError(false)
      //   setIntError(false)
      //   getResult(interest, duration, loan)
      // }

      if (loanAmt < depositeAmt) {
        setShowerror(true)
        setDepError(true)
        setIntError(true)
        setResult("")
      } else {
        setShowerror(false)
        setDepError(false)
        setIntError(false)
        getResult(interest, duration, loanAmt)
      }
    } else {
      //setShowerror(true)
      setDepError(false)
      setIntError(false)
      setResult("")
    }
    // }
  }, [defaultResult, purchasePrice, deposit, loan, interest, duration, prefix])

  const [showTable, setShowTable] = useState(false);

  const toggleTable = () => {
    setShowTable(!showTable);
  };


  // DO NOT DO ANY CHNAGES - END
  // console.log('cost fee', mortgageCosts)
  return (
    <div className="form mortgate-form">
      {showerror && (
        <div className="alert-error">
          {/* {depositError == false && intError == false &&
            <p id="error">* All fields are required</p>
          } */}
          {depositError == true && intError == true &&
            <p id="error">* Down Payment is greater than the Loan amount</p>
          }
          {depositError == false && intError == true &&
            <p id="error">* Down Payment and Loan amount are same</p>
          }
        </div>
      )}
      <Form
        name="MortgageCalc"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="bot-field" />

        <Row>

          <Col xs={6} xl={3} className="pr-0">
            <Form.Group controlId="deposit">
              <div className="custom-float">
                <Form.Label className="form-field-label text-xs-bold">Down Payment: 
                  <OverlayTrigger
                    key={'top'}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-top1`}>
                        A percentage of the home price paid up front and in cash. Usually at least 20%
                      </Tooltip>
                    }
                  >
                    <span className="helper-icon"><HelperIcon /></span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="deposit_available"
                  placeholder="Down Payment"
                  value={deposit}
                  onChange={handleDeposit}
                />
              </div>
            </Form.Group>
          </Col>

          <Col xs={6} xl={3} className="px-0">
            <Form.Group controlId="interest_rate">
              <div className="custom-float">
                <Form.Label className="form-field-label text-xs-bold">Interest Rate:</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="interest_rate"
                  value={interest}
                  placeholder="Interest rate (%)"
                  onChange={(e) => { handleInterest(e) }}
                />
              </div>
            </Form.Group>
          </Col>


          <Col xs={6} xl={3} className="pr-0 px-xl-0">
            <Form.Group controlId="duration">
              <div className="custom-float  custom-selectbox">
                <Form.Label className="form-field-label text-xs-bold">Term:</Form.Label>
                <Select
                  name="teams"
                  defaultValue={{ value: 25, label: "25 Years" }}
                  options={durationOptions}
                  onChange={handleDuration}
                  placeholder="Terms"
                  styles={styles}
                  classNamePrefix={"select-option"}
                  className={`customselectbox`}
                />

              </div>
            </Form.Group>
          </Col>

          <Col xs={6} xl={3} className="px-0">
            <Form.Group controlId="purchase_price">
              <div className="custom-float">
                <Form.Label className="form-field-label text-xs-bold">Loan:
                  <OverlayTrigger
                    key={'top'}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-top1`}>
                        Max of 80% of property value
                      </Tooltip>
                    }
                  >
                    <span className="helper-icon"><HelperIcon /></span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="purchase_price"
                  value={purchasePrice}
                  placeholder="Loan"
                  onChange={handlePrice}
                />
              </div>
            </Form.Group>
          </Col>
          <Col className="morgage-cost-details-wrapper">
            <div className="morgage-payment-results">
              {result && (
                <div className="monthly-payment">
                  <p className="pay-text text-xs-bold">Monthly Payment:</p>
                  <h5 className="pay monthly-amount">{prpocurr} {result}</h5>
                </div>
              )}
            </div>
            <div className="morgage-cost-fee-wrpper">
                <div className="view-toggler">
                  <button onClick={toggleTable} className="text-xs view-toggler-link">
                    {showTable ? 'Hide Mortgage Costs and Fees' : 'View Mortgage Costs and Fees'}
                  </button>
                </div>
                {showTable && (
                <div className="cost-table">
                <h6>Mortgage Costs and Fees</h6 >
                <Table responsive borderless className="morgage-cost-table"> 
                <tbody>
                  <tr>
                    <td className="text-xs-bold">Land Department Fee<OverlayTrigger
                    key={'top'}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-top1`}>
                      4% of purchase price + {landDeptFeePlus}
                      </Tooltip>
                    }
                  >
                    <span className="helper-icon"><HelperIcon /></span>
                  </OverlayTrigger></td> 
                    <td align="right" className="text-xs">{mortgageCosts.landDeptFee} {prpocurr}</td>
                  </tr> 
                  <tr>
                    <td className="text-xs-bold">Trustee Office Fee incl. 5% VAT<OverlayTrigger
                    key={'top'}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-top1`}>
                      AED {numberFormat(registrationFee)} cash
                      </Tooltip>
                    }
                  >
                    <span className="helper-icon"><HelperIcon /></span>
                  </OverlayTrigger></td> 
                    <td align="right" className="text-xs">{mortgageCosts.registrationFee} {prefix}</td>
                  </tr>
                  <tr>
                    <td className="text-xs-bold">Mortgage Registration<OverlayTrigger
                    key={'top'}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-top1`}>
                      {mortgageRegnFee} of loan + AED {mortgageRegnFeePlus}
                      </Tooltip>
                    }
                  >
                    <span className="helper-icon"><HelperIcon /></span>
                  </OverlayTrigger></td>
                    <td align="right" className="text-xs">{mortgageCosts.mortgageRegnFee} {prefix}</td>
                  </tr>
                  <tr>
                    <td className="text-xs-bold">Real Estate Agency Fee incl. 5% VAT<OverlayTrigger
                    key={'top'}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-top1`}>
                      {brokerCommissionFee}% of purchase price
                      </Tooltip>
                    }
                  >
                    <span className="helper-icon"><HelperIcon /></span>
                  </OverlayTrigger></td> 
                    <td align="right" className="text-xs">{mortgageCosts.brokerCommissionFee} {prefix}</td>
                  </tr>
                  <tr>
                    <td className="text-xs-bold">Bank Arrangement Fee<OverlayTrigger
                    key={'top'}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-top1`}>
                      Upto {bankArrangementFee}% of the loan amount to be agreed directly with lender
                      </Tooltip>
                    }
                  >
                    <span className="helper-icon"><HelperIcon /></span>
                  </OverlayTrigger></td> 
                    <td align="right" className="text-xs">{mortgageCosts.bankArrangementFee} {prefix}</td>
                  </tr>
                  <tr>
                    <td className="text-xs-bold">Valuation<OverlayTrigger
                    key={'top'}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-top1`}>
                     AED 2,500 to AED 3,000 depending on bank, please discuss with your leader
                      </Tooltip>
                    }
                  >
                    <span className="helper-icon"><HelperIcon /></span>
                  </OverlayTrigger></td> 
                    <td align="right" className="text-xs">{mortgageCosts.valuationFee} {prefix}</td>
                  </tr>
                  <tr>
                    <td className="text-xs-bold left">Conveyancing Fee incl. 5% <OverlayTrigger
                    key={'top'}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-top1`}>
                    Sales progression
                      </Tooltip>
                    }
                  >
                    <span className="helper-icon"><HelperIcon /></span>
                  </OverlayTrigger></td> 
                    <td align="right" className="text-xs">{mortgageCosts.conveyancingFee} {prefix}</td>
                  </tr> 
                  <tr>
                    <td className="text-xs-bold"><strong>TOTAL PURCHASE COSTS</strong></td> 
                    <td align="right" className="text-md"><span className="text-md-bold">{mortgageCosts.totalFee}</span> {prefix}</td>
                  </tr>
                  <tr>
                    <td className="text-xs-bold"><strong>TOTAL REQUIRED UPFRONT</strong> <OverlayTrigger
                    key={'top'}
                    placement={'top'}
                    overlay={
                      <Tooltip id={`tooltip-top1`}>
                  Deposit + Total purchase costs
                      </Tooltip>
                    }
                  >
                    <span className="helper-icon"><HelperIcon /></span>
                  </OverlayTrigger></td> 
                    <td align="right" className="text-md"><span className="text-md-bold">{mortgageCosts.upFront}</span> {prefix}</td>
                  </tr>
                </tbody>
              </Table>
                </div>
                    )}
            </div>
          </Col>

        </Row>
      </Form>

    </div>
  )
}

MortgageCalc.defaultProps = {
  propertyPrice: defaultValues.PROPERTY_VALUE,
  depositAmount: defaultValues.DEPOSIT,
  loadDuration: defaultValues.DURATION,
  interestRate: defaultValues.INTEREST,
  currency: process.env.CURRENCY
    ? process.env.CURRENCY
    : defaultValues.CURRENCY,
  defaultResult: defaultValues.DEFAULT_RESULT,
  pricePrefix: defaultValues.PRICE_PREFIX,
  // instantResult: defaultValues.INSTANT_RESULT,
  // durationSuffix: defaultValues.DURATION_SUFFIX,
  // yearSuffix: defaultValues.YEAR_SUFFIX,
  // yearSelect: defaultValues.YEAR_SELECT,
}

MortgageCalc.propTypes = {
  propertyPrice: PropTypes.any,
  depositAmount: PropTypes.any,
  loadDuration: PropTypes.any,
  interestRate: PropTypes.any,
  currency: PropTypes.string,
  defaultResult: PropTypes.any,
  pricePrefix: PropTypes.any,
  // instantResult: PropTypes.any,
  // durationSuffix: PropTypes.any,
  // yearSuffix: PropTypes.any,
  // yearSelect: PropTypes.any
}

export default MortgageCalc
