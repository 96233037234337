import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap";
import PropertyBannerImg from "../../../images/propertydetails/propertybanner-mobile.jpg";
import propertybannerTablet from "../../../images/propertydetails/propertybannerTable.jpg";
import propertybannerDesktop from "../../../images/propertydetails/propertybanner-desktop.jpg";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import PropertyVideo from "../../../images/propertydetails/vidoePoster.jpg";
import PlayVideo from "../../Play/PlayVideo";
import Slider from "react-slick";
import FsLightbox from 'fslightbox-react';
import { ExternalLinkIcon, PlayIcon } from "../../Icons/Icons";
import "./PropertyBanner.scss"
import {
  isIOS,
  isMobileOnly,
  isBrowser,
  isMobile
} from "react-device-detect";

// styles

// markup

const PropertyBanner = (props) => {
  const [isPlay, setPlay] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [floorToggler, setFloorToggler] = useState(false);
  const [indexed, setIndex] = useState(1)
  const settings = {
    dots: false,
    lazyLoad: false,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 0.5,
    infinite: true,
    arrows: true,
    mobileFirst: true,
    autoplay: true,
    responsive: [

      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1

        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        },
      },
    ],
  }


  const chunkSize = 2;
  const arr = props.data.images
  //console.log(arr.length);
  const groups = arr.map((e, i) => {
    if(isIOS || isMobileOnly || isMobile) {
      return arr.slice(i, i + chunkSize);
    } else {
      return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;
    }
  }).filter(e => { return e; });
  //console.log(groups.length);
  const imagecount = arr.length;
  const afterChangeHandler = (d) => {
    
    if(isIOS || isMobileOnly || isMobile) {
      setIndex(d + 1);
    } else {
      let di = 1;
      let fr = d - 0.5;
      di = fr + d +   0.5 + 1;
      if(imagecount>30 && di>=imagecount) {
        di = di - 1;
      } else if(imagecount<=30 && di>imagecount) {
        di = di - 1;
      }
      //console.log("F",di);
      setIndex(di);
      //console.log("DI",di);
    } 
  }

  //console.log("imgg", groups)
  return (

    <React.Fragment>
      <div className="property-banner">
        <Slider  {...settings}
          afterChange={
            v => afterChangeHandler(v)
          }

        >
          {groups && groups.map((node, i) => {

            let processedImages = JSON.stringify({});
            if (props.data?.imagetransforms?.images_Transforms) {
              processedImages = props.data.imagetransforms.images_Transforms;
            }

            return (
              <div className="d-flex">
                <div className="col-img width100-sm-xs">
                  {/* <img src={node[0].url} alt={`Property Image - Dacha`} className="propertydetailImg"/> */}
                  <ImageTransform imagesources={node[0].url} renderer="srcSet" imagename="property.images.detail" attr={{ alt: props.data.title + ' - Dacha', class: '' }} imagetransformresult={processedImages} id={props.data.id} />
                  {/* <span className="full-view-btn" onClick={ () => setToggler(!toggler) }>
                    <i className="full-view-btn-icon"></i>
                  </span>
            */}

                </div>
                {node.length >= 2 ?
                  <div className="col-img d-none d-xl-block img-zoom 2">
                    {/* <ImageTransform imagesources={node[1].url} renderer="srcSet" imagename="property.images.detail" attr={{ alt: '', class: '' }} imagetransformresult={processedImages} id={props.data.id} /> */}
                    {/* <img src={node[1].url} alt={`Property Image - Dacha`} className="propertydetailImg" /> */}
                    <ImageTransform imagesources={node[1].url} renderer="srcSet" imagename="property.images.detail" attr={{ alt: props.data.title + ' - Dacha', class: '' }} imagetransformresult={processedImages} id={props.data.id} />
                    {/* <strong className="video-btn" onClick={e => { setPlay(true) }}>
                    <i className="icon-video"></i> 
                  </strong> */}
                  </div> :
                  <div className="col-img d-none d-xl-block img-zoom 2">
                    {/* <img src={node[0].url} alt={`Property Image - Dacha`} className="propertydetailImg"/> */}
                    <ImageTransform imagesources={node[0].url} renderer="srcSet" imagename="property.images.detail" attr={{ alt: props.data.title + ' - Dacha', class: '' }} imagetransformresult={processedImages} id={props.data.id} />
                    {/* <ImageTransform imagesources={node[0].url} renderer="srcSet" imagename="property.images.detail" attr={{ alt: '', class: '' }} imagetransformresult={processedImages} id={props.data.id} /> */}
                  </div>
                }
              </div>
            )
          })}


        </Slider>
        <div className="property-banner-content">
          <Container>
          <div className="property-banner-btn">            
            <button className="banner-btn text-xxs" onClick={() => setToggler(!toggler)}> <span className="banner-btn-icon"><ExternalLinkIcon /></span> {indexed}/{Math.round((props.data?.images?.length))} Photos</button>
            {props.data?.video_url &&
            <button className="banner-btn text-xxs" onClick={() => setPlay(true)}>
              <span className="banner-btn-icon"><PlayIcon /></span> <span className="d-none d-lg-flex watch-text">Watch</span>Video
            </button>
            }
            {props.data.floorplan && props.data.floorplan.length > 0 && 
              <button className="banner-btn text-xxs" onClick={() => setFloorToggler(!floorToggler)}>Floorplan</button>
            }
          </div>
          </Container>
        </div>
        <PlayVideo
          isOpen={isPlay}
          isCloseFunction={setPlay}
          videoId="luZRKtAUb2A"
          isAutoPlay={1}
        />
        <FsLightbox
          toggler={toggler}
          sources={props.data.images.map(img => img.srcUrl)}
          type='image'

        />
        {props.data.floorplan && props.data.floorplan.length > 0 &&
          <FsLightbox
            toggler={floorToggler}
            sources={props.data.floorplan.map(img => img?.url)}
            type='image'
          />
        }
      </div>

    </React.Fragment>
  )
}

export default PropertyBanner
